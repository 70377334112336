<template>
  <div>
    <el-dialog
        :visible.sync="topUpTipDialogVisible"
        width="345px"
        class="topUpTipDialog"
        height="304px">
      <div style="text-align: center">
        <img style="margin-top: 26px" src="../../assets/img/rebate/pic_pc.svg" alt="">
        <div style="margin-top: 30px">网站新增“交易账户”作为机器人运转的专属扣费账户，您需将资金划转至“交易账户”以保证机器人的正常运行。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button  style="width: 100%;background: #AB54DB;color: #FFFFFF"  @click="handleClickTip">我知道了</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :visible.sync="topUpDialogVisible"
        width="510px"
        :title="$t('top_up.property_top_up')"
        class="topUpDialog"
        height="304px">
      <div class="topUp">
        <div class="topUp_top" style="margin-top: 10px">
          <div><span style="display: inline-block;font-size: 14px;color: #1E2329;vertical-align: middle;">充值账户</span>
            <tooltip content="一旦我们收到您充值的资产，会自动上账到您所选择的账户。" style="display:inline-block;vertical-align: middle;margin-top: 1px"></tooltip>
          </div>
          <el-select style="display:block;position: relative;margin-top: 5px" disabled v-model="topUpInfo.rechargeTarget" name="">
            <el-option value="1" label="资金账户"></el-option>
            <el-option value="2" label="交易账户（燃料费）"></el-option>
          </el-select>
        </div>
        <div class="topUp_top" style="margin-top: 15px">
          <span style="display:block;width: 15%;color: #1E2329">{{$t('top_up.main_net')}}</span>
          <div style="display: inline-block; width: 100%;margin-top: 5px"><el-input disabled placeholder="TRC20"></el-input></div>
        </div>
        <div>{{$t('top_up.wallet_address')}}</div>
        <div><img :src="topUpInfo.qrCode" alt="" style="max-width: 130px"></div>
        <div>
          <span>{{topUpInfo.base58Address}}</span><img @click="handleCopyAddress" style="cursor: pointer" src="../../assets/img/rebate/copy.svg" alt="">
        </div>
        <div>{{$t('top_up.important_tip')}}</div>
        <div>
          <ul>
<!--            <li>{{$t('top_up.least_top_up_money')}} 50USDT</li>-->
            <li style="color:red;">{{$t('top_up.top_up_tip1')}}</li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="topUpDialogVisible=false">{{$t('top_up.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {getUserAddress, getUserAddressQrCode, setRechargeAccount} from "../../server/property";
import qr from '../../assets/js/qrcode'
import Tooltip from "../common/tooltip";

export default {
  name: "top_up",
  components: {Tooltip},
  data(){
    return{
      topUpTipDialogVisible:false,
      topUpDialogVisible:false,
      topUpInfo:{
        base58Address:'',
        qrCode:'',
        rechargeTarget: '1',
      },
    }
  },
  props:['userInfo'],
  methods:{
    handleClickTip(){
      // this.topUpTipDialogVisible=false
      this.topUpDialogVisible=true
      axios.post(`https://houtai.felton.fit/fqwallet/api/tron-usdt/user-address?userId=${this.userInfo.id}`).then(res=>{
        if (res.data.statusCode!==200) return this.$message.error(this.$t('top_up.get_tip'))
        this.topUpInfo.base58Address=res.data.data.base58Address
        this.topUpInfo.qrCode='https://houtai.felton.fit/wallet/qrcode/'+res.data.data.qrCode
      })
    },
    getTopUpInfo(){
      // this.topUpTipDialogVisible=true
      // this.handleClickTip()
      this.getUserAddress()
    },

    handleCopyAddress(){
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', this.topUpInfo.base58Address);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$message.success(this.$t('top_up.copy_success'))
      }
      document.body.removeChild(input);
    },

    async getUserAddress(){
      const res = await getUserAddress()
      if (!res) return
      if (res.success){
        this.topUpInfo.base58Address = res.data.base58Address
        await this.getUserAddressQrCode()
      } else {
        this.$message.error(res.message)
      }
    },
    async getUserAddressQrCode(){
      const res = await getUserAddressQrCode()
      if (!res) return
      this.topUpDialogVisible = true
      this.topUpInfo.qrCode = window.URL.createObjectURL(res)
    },
    // async changeRechargeAccount(){
    //   const res = await setRechargeAccount(this.topUpInfo.rechargeTarget)
    //   if (!res) return
    //   if (res.success){
    //
    //   } else {
    //     this.$message.error(res.message)
    //   }
    //
    // }
  }
}
</script>

<style scoped>


/*充值的对话框*/
/deep/ .topUpDialog .el-dialog__body {
  padding:15px 26px !important;
}
.topUp>div:first-child{
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(2){
  margin: 8px 0 24px 0px;
}
.topUp>div:nth-child(3){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(4) img{
  width: 136px;
  height: 136px;
  display: inline-block;
  margin: 8px 0 16px 0;
}
.topUp>div:nth-child(5){
  padding: 11px 0 24px 0;
  border-top: 1px solid #EAECEF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.topUp>div:nth-child(5)>span{
  font-size: 12px;
  color: #1E2329;
}
.topUp>div:nth-child(5)>img{
  display: inline-block;
}
.topUp>div:nth-child(6){
  font-size: 12px;
  color: #72777E;
}
.topUp>div:nth-child(7)>ul{
  margin: 8px 0 0 0 ;
  padding-left: 20px;
}
.topUp>div:nth-child(7)>ul>li{
  font-size: 14px;
  color: #1E2329;
  line-height: 25px;
}
.topUp>div:nth-child(7)>ul>li:nth-child(2){
  color:red;
}

@media screen  and (max-width: 900px){
  .topUp>div:nth-child(4) img{
    width: 100px;
    height: 100px;
    margin: 8px 0 8px 0;
  }
  .topUp>div:nth-child(5){
    padding: 10px 0 11px 0;
  }
  /deep/ .topUpDialog .el-dialog__body {
    padding:5px 20px !important;
  }
  /deep/ .topUpDialog .el-form-item {
    margin-bottom: 8px;
  }
}

 >>> .topUpTipDialog >div{
   background: linear-gradient(180deg, #E2ECFF 0%, #FFFFFF 100%);
}
>>> .topUpTipDialog .el-dialog__header{
  padding: 0;
}
</style>
