import axios from "axios";
// import top_up from "../views/rebate/top_up";
export const topUp={
    methods:{
        getTopUpInfo(){
            this.$refs.top_up.getTopUpInfo()
        },
    }
}
export const transferMixin={
    methods: {
        handleTransfer(e){
            this.$refs.transfer.handleClickTypeTransfer(e)
        }
    }
}
export const dialogTipMixin={
    methods:{
        handleShowDialogTip(changeNum){
            if ((typeof changeNum)=='object') return  this.$refs.dialogTip.ClickTipDialogVisible=true
            this.$refs[changeNum].ClickTipDialogVisible=true
        }
    }
}

export const bindPhoneOrEmail={
    methods:{
        handleBindPhone(){
            this.$refs.alter.changePhoneDialogVisible=true
        },
        handleBindEmail(){
            this.$refs.alter.bindEmailDialogVisible=true
        }
    }
}

export const dialogDealDetail={
    methods:{
        handleShowDialog(){
            this.$refs.alter.dialogDetail=true
        },
        // handleBindEmail(){
        //     this.$refs.alter.bindEmailDialogVisible=true
        // }
    }
}

//添加马丁对话框
export const MartinAddDialogMixin={
    methods:{
        handleShowMartinAddDialog(type=1,kinds){
            sessionStorage.setItem('martin',JSON.stringify(type))    //1是添加 2是重启  3是修改
            if (type==2) return this.$refs.MartinAddDialogRef.handleOpenType(kinds)
            this.$refs.MartinAddDialogRef.handleOpenType(type)
        }
    }
}
