<template>
  <el-dialog
      width="510px"
      :visible.sync="capitalTransferDialogVisible"
      :title="$t('transfer.capital_transfer')"
      @close="handleCloseDialog"
      height="500px">
    <div class="transfer">
      <div class="select-type" style="display: flex;align-items: center">
        <div>
          <div style="margin-bottom: 6px">{{$t('transfer.from')}}</div>
          <el-select v-model="fromSelectValue" :placeholder="$t('transfer.please_select')" @change="handleChangeFromSelect">
            <el-option
                v-for="item in fromAccountType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <div class="remain">{{$t('transfer.balance')}} : {{fromMoney | roundTwoFilter}}USDT</div>
        </div>
        <div><i @click="exchangeType" style="cursor: pointer;color: #52C41A;margin: 0 16px;font-size: 16px;transform: rotate(90deg)" class="el-icon-sort"></i></div>
        <div>
          <div style="margin-bottom: 6px">{{$t('transfer.to')}}</div>
          <el-select v-model="toSelectValue" :placeholder="$t('transfer.please_select')" @change="handleChangeToSelect">
            <el-option
                v-for="item in toAccountType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <div class="remain">{{$t('transfer.balance')}} : {{toMoney | roundTwoFilter}}USDT</div>
        </div>
      </div>
      <div style="position: relative;margin-top: 24px;">
        <div style="margin-bottom: 6px">{{$t('transfer.transfer_money')}}</div>
        <el-input v-model="transferMoney" @input="checkinput($event)"></el-input>
        <div style="font-size: 14px;color: #B0B5BA;position: absolute;top: 38px;right: 66px">USDT</div>
        <div style=" width:1px;height:20px;position: absolute;top: 36px;right: 54px;border-right:1px solid #EAECEF;"></div>
        <div @click="handleMoneyAll" style="font-size: 14px;font-weight: 400;color: #AB54DB;position: absolute;top: 35px;right: 12px;cursor: pointer">{{$t('transfer.all')}}</div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="width: 100%"  @click="handleTransfer">{{$t('transfer.confirm_transfer')}}</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {checkTwoPlaceGeneral} from "../../utils/validate";
import {transferMoney} from "../../server/property";
import {getUserInfo} from "../../server/rebate";

export default {
  name: "transfer",
  data(){
    return{
      //  資金劃轉的對話框
      capitalTransferDialogVisible:false,
      //  資金劃轉
      fromAccountType: [{
        value: '1',
        label: this.$t('transfer.capital_account')
      }, {
        value: '2',
        label: this.$t('transfer.trading_account')
      }],
      toAccountType: [
        {
          value: '2',
          label: this.$t('transfer.trading_account')
        }],
      fromSelectValue: '1',
      toSelectValue:'2',
      fromMoney:0,
      toMoney:0,
      // 劃轉的金額
      transferMoney:'',
      userInfo:{}
    }
  },
  created() {
    this.getUserInfo()
  },
  methods:{
    async getUserInfo(){
      const res= await getUserInfo()
      // this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
      this.userInfo=res.data
      localStorage.setItem('userInfo', JSON.stringify(res.data))
      this.fromMoney=this.userInfo.asset_balance
      this.toMoney=this.userInfo.deposit
    },
    handleChangeFromSelect(e){
      if (e==1) {
        this.toAccountType = [{
          value: '2',
          label: this.$t('transfer.trading_account')
        }]
        this.toSelectValue='2'
        this.fromMoney=this.userInfo.asset_balance
        this.toMoney=this.userInfo.deposit
      }
      else if (e==2) {
        this.toAccountType = [{
          value: '1',
          label: this.$t('transfer.capital_account')
        }]
        this.toSelectValue='1'
        this.fromMoney=this.userInfo.deposit
        this.toMoney=this.userInfo.asset_balance
      }
      else {
        this.toAccountType = [{
          value: '1',
          label: this.$t('transfer.capital_account')
        }, {
          value: '2',
          label: this.$t('transfer.trading_account')
        }]
        this.toSelectValue='1'
        this.fromMoney=this.userInfo.commission_balance
        this.toMoney=this.userInfo.asset_balance
      }
      console.log(this.fromSelectValue, this.toSelectValue);

    },
    async handleTransfer(){
      // transferMoney()
      if (!this.transferMoney || this.transferMoney=='' || this.transferMoney == 0) return this.$message.warning(this.$t('transfer.please_input_transfer_money'))
      if (!checkTwoPlaceGeneral(this.transferMoney)) return  this.$message.error(this.$t('transfer.restrict_input'))
      const res=await transferMoney(this.fromSelectValue, this.toSelectValue,this.transferMoney)
      if (!res.success) return this.$message.error(res.message)
      this.$message.success(this.$t('transfer.transfer_success'))
      this.capitalTransferDialogVisible=false
      const userInfo=await getUserInfo()
      localStorage.setItem('userInfo',JSON.stringify(userInfo.data))
      this.fromSelectValue='1'
      this.toSelectValue='2'
      this.userInfo=userInfo.data
      this.handleChangeFromSelect(1)
      this.$emit('updateUserInfo')
      this.$emit('renderPropertyEcharts')
    },
    handleChangeToSelect(e){
      if (e==1){
        this.toSelectValue='1'
        this.toMoney=this.userInfo.asset_balance
      }
      else if (e==2){
        this.toSelectValue='2'
        this.toMoney=this.userInfo.deposit
      }
    },
    exchangeType(){
      if (this.fromSelectValue==3) return this.$message.warning(this.$t('transfer.warn_not'))
      if (this.fromSelectValue==1){
        this.toAccountType = [{
          value: '1',
          label: this.$t('transfer.capital_account')
        }]
      }
      else if (this.fromSelectValue==2){
        this.toAccountType = [{
          value: '2',
          label: this.$t('transfer.trading_account')
        }]
      }
      let c=this.fromSelectValue
      let d=this.fromMoney
      this.fromSelectValue=this.toSelectValue
      this.fromMoney=this.toMoney
      this.toSelectValue=c
      this.toMoney=d
    },
  //  点击全部
    handleMoneyAll(){
      if (this.fromSelectValue=='1') this.transferMoney=this.$options.filters['roundTwoFilter'](this.userInfo.asset_balance);
      if (this.fromSelectValue=='2') this.transferMoney=this.$options.filters['roundTwoFilter'](this.userInfo.deposit)
      if (this.fromSelectValue=='3') this.transferMoney=this.$options.filters['roundTwoFilter'](this.userInfo.commission_balance)
      if (this.transferMoney <= 0){
        this.transferMoney = ''
        return this.$message.warning(this.$t('transfer.not_negative_numbers'))
      }
    },
    handleClickTypeTransfer(e){
        this.fromSelectValue=e.toString()
        this.handleChangeFromSelect(e)
        this.capitalTransferDialogVisible=true
    },
    handleCloseDialog(){
      this.transferMoney=''
    },
    checkinput(value, name, num) {       // 通过正则过滤小数点后两位
      let inputValue =
          ("" + value) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              // .replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,"") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || "" // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到10位小数
      this.transferMoney = inputValue
    },

  }
}
</script>

<style scoped>
>>>.el-dialog__body {
  padding:15px 26px !important;
}
>>>.el-dialog__header {
  padding-bottom: 0;
}
</style>
