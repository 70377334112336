import request from "../utils/request";

/**
 * 資金劃轉
 * @param from
 * @param to
 * @param money
 * @returns {AxiosPromise}
 */
export const transferMoney=(from,to,money)=>request({
    method: 'post',
    url: '/wallet/transferInside',
    params: {from,to,money},
})

/***
 * 獲取充值記錄
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export const getTopUpRecord=(pageNum=1,pageSize=10)=>request({
    method: 'post',
    url: '/wallet/getRechargeRecordListByPage',
    params: {pageNum,pageSize},
})
/**
 * 獲取提現記錄
 */
export const getWithDrawRecord=(pageNum=1,pageSize=10)=>request({
    method: 'post',
    url: '/wallet/getWithdrawalRecordListByPage',
    params: {pageNum,pageSize},
})

/**
 * 資產賬戶記錄
 */
export const getPropertyRecord=(pageNum=1,pageSize=10)=>request({
    method: 'post',
    url: '/wallet/getAssetChangeRecordListByPage',
    params: {pageNum,pageSize},
})
/**
 * 機器人交易記錄
 */
export const getDealRecord=(pageNum=1,pageSize=10)=>request({
    method: 'post',
    url: '/wallet/getDepositRecordListByPage',
    params: {pageNum,pageSize},
})
/**
 * 返傭獎金記錄
 */
export const getRebateBonusRecord=(pageNum=1,pageSize=10)=>request({
    method: 'post',
    url: '/wallet/getCommissionRecordListByPage',
    params: {pageNum,pageSize},
})

/**
 * 购买点卡记录
 * @returns {AxiosPromise}
 */
export const getPointCardLog = () => request({
    method: 'POST',
    url: '/wallet/getPointCardLog'
})

/**
 * 获取充值地址
 * @returns {AxiosPromise}
 */
export const getUserAddress = (requestTimes = 10) => request({
    method: 'POST',
    url: '/wallet/getUserAddress',
    params: {requestTimes}
})

/**
 * 获取地址的二维码
 * @returns {AxiosPromise}
 */
export const getUserAddressQrCode = (requestTimes = 10) => request({
    method : 'POST',
    url: '/wallet/getUserAddressQrCode',
    params: {requestTimes},
    responseType:'blob',
})

/**
 * 切换充值账户
 * @param rechargeTarget
 */
export const setRechargeAccount = (rechargeTarget) => request({
    method: 'POST',
    url: '/wallet/setRechargeAccount',
    params: {rechargeTarget}
})

/**
 * 购买点卡
 * @param {*} cardAmount
 */
export const buyPointCard = (cardAmount) => request({
    method: 'GET',
    url: '/wallet/buyPointCard',
    params: {cardAmount}
})
